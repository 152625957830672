import * as types from '../../constants/actionTypes';
import { getAxiosInstance } from '../../helpers/interceptors';
import { BASE_API_URL, ITEMS_PER_PAGE } from '../../constants';
import { t } from '../../services/i18n';
import { Schemas } from '../../constants/schema';
import FileService from '../../services/FileService';
import { getSearchQuery } from '../../services/equipments';
const axios = getAxiosInstance();

export const newEquipment = equipment => {
  return dispatch => {
    dispatch(newEquipmentRequest());
    return axios
      .post(`${BASE_API_URL}/equipment/new`, equipment)
      .then(response => {
        dispatch(newEquipmentSuccess(response));
      })
      .catch(error => {
        console.error(error);
        dispatch(newEquipmentFailure(error));
      });
  };
};

export const updateEquipment = equipment => {
  return dispatch => {
    dispatch(updateEquipmentRequest());
    return axios
      .put(`${BASE_API_URL}/equipment/${equipment.id}/update`, equipment)
      .then(response => {
        dispatch(updateEquipmentSuccess(response));
      })
      .catch(error => {
        console.error(error);
        dispatch(updateEquipmentFailure(error));
      });
  };
};

export const toggleArchived = payload => {
  return dispatch => {
    dispatch(toggleArchivedRequest());
    return axios
      .put(`${BASE_API_URL}/equipment/switch-archive`, payload)
      .then(response => {
        dispatch(toggleArchivedSuccess(response, payload.ids));
      })
      .catch(error => {
        console.error(error);
        dispatch(toggleArchivedFailure(error));
      });
  };
};

export const deleteEquipment = id => {
  return dispatch => {
    dispatch(deleteEquipmentRequest(id));
    return axios
      .delete(`${BASE_API_URL}/equipment/${id}/delete`)
      .then(response => {
        dispatch(deleteEquipmentSuccess(id));
      })
      .catch(error => {
        console.error(error);
        dispatch(deleteEquipmentFailure(error));
      });
  };
};

export const getEquipment = id => {
  return dispatch => {
    dispatch(getEquipmentRequest(id));
    return axios
      .get(`${BASE_API_URL}/equipment/${id}/get`)
      .then(response => {
        dispatch(getEquipmentSuccess(id, response));
      })
      .catch(error => {
        console.error(error);
        dispatch(getEquipmentFailure(error));
      });
  };
};

export const hasControls = id => {
  return dispatch => {
    dispatch(hasControlsRequest(id));
    return axios
      .get(`${BASE_API_URL}/equipment/${id}/has-controls`)
      .then(response => {
        dispatch(hasControlsSuccess(id, response.data));
        return response.data;
      })
      .catch(error => {
        console.error(error);
        dispatch(hasControlsFailure(error));
        return false;
      });
  };
};

export const fetchEquipments = (
  skip = 1, // TODO Remove skip from all call #REFACTO
  limit = ITEMS_PER_PAGE,
  criteria = {},
  sort = {}
) => {
  return (dispatch, getState) => {
    dispatch(fetchEquipmentsRequest());
    const req = getSearchQuery(getState(), criteria, limit);
    return axios
      .post(
        `${BASE_API_URL}/equipment/paginate/${req.page}/${req.offset}`,
        req.body
      )
      .then(response => {
        dispatch(fetchEquipmentsSuccess(response));
      })
      .catch(error => {
        console.error(error);
        dispatch(fetchEquipmentsFailure(error));
      });
  };
};
export const scanEquipment = query => {
  return (dispatch, getState) => {
    return axios
      .post(`${BASE_API_URL}/equipment/filter`, query)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
        return null;
      });
  };
};

export const MODAL_ITEM_PER_PAGE = 100;
export const findEquipmentsByIds = (
  query,
  skip = 1,
  limit = MODAL_ITEM_PER_PAGE
) => {
  return (dispatch, getState) => {
    dispatch(findEquipmentsRequest());
    return axios
      .post(`${BASE_API_URL}/equipment/byids/${skip}/${limit}`, query)
      .then(response => {
        dispatch(findEquipmentsSuccess(response));
        return response.data;
      })
      .catch(error => {
        console.error(error);
        dispatch(findEquipmentsFailure(error));
      });
  };
};

export const checkEquipmentUnicity = (attribute, value, id = null) => {
  return dispatch => {
    dispatch({
      type: types.CHECK_EQUIPMENT_REQUEST,
      payload: { attribute }
    });
    return axios
      .post(`${BASE_API_URL}/equipment/is-unique`, { [attribute]: value, id })
      .then(response => {
        dispatch({
          type: types.CHECK_EQUIPMENT_SUCCESS,
          payload: { canUse: response.data, attribute }
        });
      })
      .catch(error => {
        dispatch({
          type: types.CHECK_EQUIPMENT_FAILURE
        });
      });
  };
};
export const importEquipments = (file, options, companyId = '') => {
  return dispatch => {
    // dispatch({
    //   type: types.IMPORT_EQUIPMENTS_REQUEST
    // });
    const service = new FileService();
    const formData = service.setInForm(file);
    return axios
      .post(
        `${BASE_API_URL}/equipment/import${companyId ? '/' + companyId : ''}`,
        formData,
        options
      )
      .then(response => {
        return response.data;
        // dispatch({
        //   type: types.IMPORT_EQUIPMENTS_SUCCESS,
        //   payload: { canUse: response.data, attribute }
        // });
      })
      .catch(error => {
        return null;
        // dispatch({
        //   type: types.IMPORT_EQUIPMENTS_FAILURE
        // });
      });
  };
};

export const downloadTemplate = company => {
  return dispatch => {
    // dispatch({
    //   type: types.DOWNLOAD_TEMPLATE_REQUEST
    // });
    return axios
      .get(
        `${BASE_API_URL}/equipment/import/template${
          company ? '/' + company : ''
        }`,
        {
          responseType: 'blob'
        }
      )
      .then(response => {
        return response;
        // dispatch({
        //   type: types.DOWNLOAD_TEMPLATE_SUCCESS,
        //   payload: { canUse: response.data, attribute }
        // });
      })
      .catch(error => {
        // dispatch({
        //   type: types.DOWNLOAD_TEMPLATE_FAILURE
        // });
      });
  };
};

export const exportEquipmentsXLSX = data => {
  return dispatch => {
    return axios
      .post(`${BASE_API_URL}/equipment/export`, data, {
        responseType: 'blob'
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return null;
      });
  };
};

export const exportEquipmentsPDF = body => {
  return dispatch => {
    return axios
      .post(`${BASE_API_URL}/exportrequest/new`, body, {
        responseType: 'blob'
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return null;
      });
  };
};

export const exportAllEquipmentsByIdsXlsx = body => {
  return dispatch => {
    return axios
      .post(`${BASE_API_URL}/equipment/export/byids`, body, {
        responseType: 'blob'
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return null;
      });
  };
};

export const exportAllEquipmentsXlsx = () => {
  return (dispatch, getState) => {
    const req = getSearchQuery(getState(), {}, 0);
    return axios
      .post(`${BASE_API_URL}/equipment/export/all`, req.body, {
        responseType: 'blob'
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return null;
      });
  };
};

function newEquipmentFailure(error) {
  return {
    type: types.SAVE_EQUIPMENT_FAILURE,
    message:
      error.response && error.response.data && t(error.response.data.content)
  };
}

function newEquipmentRequest() {
  return {
    type: types.SAVE_EQUIPMENT_REQUEST
  };
}

function newEquipmentSuccess(response) {
  return {
    type: types.SAVE_EQUIPMENT_SUCCESS,
    payload: response.data
  };
}

function updateEquipmentRequest() {
  return {
    type: types.UPDATE_EQUIPMENT_REQUEST
  };
}

function updateEquipmentSuccess(response) {
  return {
    type: types.UPDATE_EQUIPMENT_SUCCESS,
    payload: response.data
  };
}
function updateEquipmentFailure(error) {
  return {
    type: types.UPDATE_EQUIPMENT_FAILURE,
    message:
      error.response && error.response.data && t(error.response.data.content)
  };
}

function toggleArchivedRequest() {
  return {
    type: types.TOGGLE_ARCHIVE_REQUEST
  };
}

function toggleArchivedSuccess(response, ids) {
  return {
    type: types.TOGGLE_ARCHIVE_SUCCESS,
    payload: { response: response.data, ids }
  };
}
function toggleArchivedFailure(error) {
  return {
    type: types.TOGGLE_ARCHIVE_FAILURE,
    message:
      error.response && error.response.data && t(error.response.data.content)
  };
}

function deleteEquipmentRequest(id) {
  return {
    type: types.DELETE_EQUIPMENT_REQUEST,
    payload: id
  };
}

function deleteEquipmentFailure(error) {
  return {
    type: types.DELETE_EQUIPMENT_FAILURE,
    message:
      error.response && error.response.data && t(error.response.data.content)
  };
}

function deleteEquipmentSuccess(id) {
  return {
    type: types.DELETE_EQUIPMENT_SUCCESS,
    payload: id
  };
}

function getEquipmentRequest(id) {
  return {
    type: types.GET_EQUIPMENT_REQUEST,
    payload: id
  };
}

function getEquipmentSuccess(id, response) {
  console.log({ response });
  return {
    type: types.GET_EQUIPMENT_SUCCESS,
    payload: response.data,
    meta: {
      schema: Schemas.EQUIPMENT
    }
  };
}

function getEquipmentFailure(error) {
  return {
    type: types.GET_EQUIPMENT_FAILURE,
    message:
      error.response && error.response.data && t(error.response.data.content)
  };
}

function hasControlsRequest(id) {
  return {
    type: types.HAS_CONTROLS_REQUEST,
    payload: id
  };
}

function hasControlsSuccess(id, has) {
  return {
    type: types.HAS_CONTROLS_SUCCESS,
    payload: has
  };
}

function hasControlsFailure(error) {
  return {
    type: types.HAS_CONTROLS_FAILURE
  };
}

function fetchEquipmentsRequest() {
  return {
    type: types.FETCH_EQUIPMENT_REQUEST
  };
}

function fetchEquipmentsSuccess(response) {
  const { data, ...meta } = response.data;
  return {
    type: types.FETCH_EQUIPMENT_SUCCESS,
    payload: data,
    meta: {
      schema: Schemas.EQUIPMENTS,
      ...meta
    }
  };
}

function fetchEquipmentsFailure(error) {
  return {
    type: types.FETCH_EQUIPMENT_FAILURE,
    message:
      error.response && error.response.data && t(error.response.data.content)
  };
}

function findEquipmentsRequest() {
  return {
    type: types.FILTER_EQUIPMENT_REQUEST
  };
}

function findEquipmentsSuccess(response) {
  return {
    type: types.FILTER_EQUIPMENT_SUCCESS,
    payload: response.data,
    meta: {
      schema: Schemas.EQUIPMENTS
    }
  };
}

function findEquipmentsFailure(error) {
  return {
    type: types.FILTER_EQUIPMENT_FAILURE,
    message:
      error.response && error.response.data && t(error.response.data.content)
  };
}
