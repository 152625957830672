import React from 'react';
import Localizer from './components/hoc/Localizer';
import ErrorBoundary from './components/shared/Boundaries/ErrorBoundary';
import Routes from './Routes';
import Spinner from './components/shared/Spinner';
import { Route } from 'react-router-dom';

class App extends React.PureComponent {
  state = {
    ready: false
  };
  componentDidMount() {
    this.verifyScript = setInterval(() => {
      if (window.scriptLoaded) {
        clearInterval(this.verifyScript);
        this.setState({ ready: true });
      }
    }, 10);
  }

  render() {
    return (
      <ErrorBoundary>
        <Localizer>
          <React.Suspense fallback={<Spinner />}>
            {!this.state.ready ? (
              <Route path="*" component={Spinner} />
            ) : (
              <Routes />
            )}
          </React.Suspense>
        </Localizer>
      </ErrorBoundary>
    );
  }
}

export default App;
