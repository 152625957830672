import './helpers/polyfills/index';
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter as Router } from 'connected-react-router';
import store, { history } from './modules/store';
import App from './App';

/* Template assets start */
import './vendor.styles';
import('./vendor.scripts');
/* Template assets end */

const target = document.querySelector('#root');

const renderApp = App => {
  const element = (
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
            <Router history={history}>
                <App />
            </Router>
        </PersistGate>
      </Provider>
  );
  const root = createRoot(target);
  root.render(element)
};

renderApp(App);
