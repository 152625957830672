import * as React from 'react';
import PropTypes from 'prop-types';
import { FaSpinner } from 'react-icons/fa';

const Spinner = ({ size, spin, className = '', ...rest }) => {
  if (size === 'big')
    return (
      <div className="page-loader-wrapper">
        <div className="loader">
          <div className="preloader">
            <div className="spinner-layer pl-deep-purple">
              <div className="circle-clipper left">
                <div className="circle" />
              </div>
              <div className="circle-clipper right">
                <div className="circle" />
              </div>
            </div>
          </div>
          <p>Please wait...</p>
        </div>
      </div>
    );
  return (
    <FaSpinner
      className={`${spin && 'icon-spin'} ${className}`}
      size={typeof size === 'number' ? size : undefined}
      {...rest}
    />
  );
};

Spinner.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['sm', 'xl', 'md', 'big']),
    PropTypes.number
  ]).isRequired
};

Spinner.defaultProps = {
  size: 'big'
};

export default Spinner;
